import React from "react"
import { Link } from "gatsby"
import { queryCountList } from "./data-control"
import { SchoolCount } from "../hooks/shool-count"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const NavListItem = ({sortList, countData, linkUrl}) => {
    const countList = queryCountList(countData)
    return (
        <ul>
        {sortList.map((item) => {
            const count = countList[item.key] && countList[item.key] > 0 ? countList[item.key] : 0
            return (
            <li>
                <Link to={linkUrl + '/' + item.key} className={( count > 0 ? "" : "is-disabled")}>{item.name}<span>({count})</span></Link>
            </li>
            )
        })}
        </ul>
    )
}

const SchoolAreaBlock = ({countData=[], areaSortList=[], areaKey="", areaName="", perfCountData=[], areaSortPrefectures=[]}) => {
    const linkUrl = "/school"
    const config = useSiteMetadata()
    if (!areaKey) {
        if (countData.length === 0) {
            countData = SchoolCount()
        }
        if (areaSortList.length === 0) {
            areaSortList = config.areaSortList
        }
    }
    return (
        <div className="p-latest-list p-latest-list-school">
            {areaKey && (
            <React.Fragment>
                <h2>{areaName}の歯科衛生士学校</h2>
                <div className="c-school-nav c-school-nav-2nd">
                    {NavListItem({sortList: areaSortPrefectures, countData: perfCountData, linkUrl: `${linkUrl}/${areaKey}`})}
                </div>
            </React.Fragment>
            )}
            {!areaKey && (
            <React.Fragment>
                <h2>全国の歯科衛生士学校</h2>
                <div className="c-school-nav">
                    {NavListItem({sortList: areaSortList, countData, linkUrl})}
                </div>
            </React.Fragment>
            )}
            <Link to={linkUrl} className="c-button c-button-school">全国の歯科衛生士学校 一覧</Link>
        </div>
    )
}

const JobBlock = ({naruniha=true, shigoto=true}) => {
    return (
        <div className="p-static-list p-static-list-jobs">
            {naruniha && (
            <section>
                <h2><mark>歯科衛生士になるには</mark></h2>
                <p>
                歯科衛生士になるには、⻭科衛⽣⼠国家試験に合格する必要があります。高校卒業後に文部科学大臣指定の歯科衛生士学校や、都道府県知事指定の歯科衛生士養成所で、決められた科目を修得し、卒業することで受験資格が得られます。
                </p>
                <Link to="/naruniha" className="c-button c-button-primary">詳しくはこちら</Link>
            </section>
            )}
            {shigoto && (
            <section>
                <h2><mark>歯科衛生士の仕事内容</mark></h2>
                <p>
                歯科衛生士の仕事内容は、「歯科予防処置」「保健指導」「診療補助」「口腔機能訓練」などの専門業務があります。近年ではホワイトニングなども増えているほか、歯や口腔の健康ケアを通して、病気の予防・健康維持などのサポートも担っています。
                </p>
                <Link to="/shigoto" className="c-button c-button-primary">詳しくはこちら</Link>
            </section>
            )}
        </div>
    )
}
const CharmBlock = () => {
    return (
        <div className="p-static-list p-static-list-charm">
            <h2>歯科衛生士の魅力</h2>

            <div className="c-static-charm-nav">
            <ul>
                <li>
                    <Link to="/salary">
                        大卒より<em className="u-bgc-pink">高い初任給</em>
                    </Link>
                </li>
                <li>
                    <Link to="/shikaku">
                        一生使える<em className="u-bgc-pink">国家資格</em>
                    </Link>
                </li>
                <li>
                    <Link to="/employment">
                        就職率はなんと<em className="u-bgc-pink">9割以上</em>
                    </Link>
                </li>
                <li>
                    <Link to="/hobby">
                        趣味や遊びも<em className="u-bgc-pink">両立</em>
                    </Link>
                </li>
                <li>
                    <Link to="/medical">
                        患者さんを<em className="u-bgc-pink">笑顔にする医療職</em>
                    </Link>
                </li>
            </ul>
            </div>
        </div>
    )
}

export { NavListItem, SchoolAreaBlock, JobBlock, CharmBlock }
export default NavListItem