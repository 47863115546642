import React, { Component } from "react"
import "../css/common.css"

import Header from "./header"
import Footer from "./footer"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import { SchoolAreaBlock, JobBlock, CharmBlock } from "../components/category-block"

export default class Layout extends Component {
  state = {
    isMenuOpen: false,
  }

  toggleMenu = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  render() {
    const { children, category="", slug="", area="", prefecture="", title="", description="", keywords="", metaReplace={}, wrapperAddClassName="", showSchoolArea=false, showJob=false, showCharm=false } = this.props;
    const pageTitle = replaceData(title, metaReplace)
    const bodyAttributes = {}
    const schoolCategory = "school"
    const bodyClass = slug === "/" ? "home" : (category === "top" ? "static" : category)
    bodyAttributes['class'] = `page-${bodyClass}`
    let wrapperClass = ['l-wrapper']
    if (wrapperAddClassName.length > 1 && typeof wrapperAddClassName === 'string') {
      const addClass = wrapperAddClassName.split(' ')
      wrapperClass = wrapperClass.concat(addClass)
    }
    bodyAttributes['style'] = this.state.isMenuOpen ? 'overflow: hidden;' : ''
    const breadcrumbData = createBreadcrumbData(slug, pageTitle)

    return (
      <React.Fragment>
        <Seo title={pageTitle} description={replaceData(description, metaReplace)} slug={slug} category={category} keywords={replaceData(keywords, metaReplace)} breadcrumbData={breadcrumbData} />
        <SiteNameTag slug={slug} className='c-site-name'>歯科衛生士になるための情報マガジン めざせ！歯科衛生士</SiteNameTag>
        <div className={wrapperClass.join(' ')} id="top">
          <Helmet bodyAttributes={bodyAttributes}>
            <script type="application/javascript">
              {`
                {
                  (function(d) {
                    var config = {
                      kitId: 'whd7qks',
                      scriptTimeout: 3000,
                      async: true
                    },
                    h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
                  })(document);

                  window.onscroll = function() {
                    const scrollTop = document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop;
                    const headerElement = document.querySelector('.l-header')
                    if (scrollTop > 0) {
                      headerElement.classList.add('is-active')
                    } else {
                      headerElement.classList.remove('is-active')
                    }
                  }
                }
              `}
            </script>
          </Helmet>
          <Header onClickHandler={this.toggleMenu} />
          <main className="l-main">
            {children}
            {showSchoolArea && <SchoolAreaBlock />}
            {showJob && <JobBlock />}
            {showCharm && <CharmBlock />}
          </main>
          <Footer category={category} area={area} prefecture={prefecture} title={breadcrumbData.pageTitle} slug={slug} />
          {category !== schoolCategory && (
          <div className="p-search">
            <input type="checkbox" id="c-search-close" />
            <label for="c-search-close">閉じる</label>
            <Link to={`/${schoolCategory}`}>歯科衛生士学校を探す</Link>
          </div>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const SiteNameTag = ({children, slug, className}) => {
  if (slug === "/") {
    return <h1 className={className}>{ children }</h1>
  } else {
    return <div className={className}>{ children }</div>
  }
}

const replaceData = (target, metaReplace) => {
  if (!target) return ""
  const match = target.match(/%([^%]+)%/g)
  if (match === null) return target
  let ret = target
  for (const rep of match) {
    const key = rep.replaceAll('%', '')
    if (metaReplace[key]) {
      ret = ret.replace(rep, metaReplace[key])
    }
  }
  return ret
}

const createBreadcrumbData = (slug, title) => {
  const breadcrumb = slug.replace(/(^\/|\/$)/g, '').split('/')
  const pageTitle = title.replace(" | めざせ！歯科衛生士", "").replace(/【.+】/, "").trim()
  return {
    pageTitle : pageTitle === '全国の歯科衛生士学校' ? pageTitle : pageTitle.replace('の歯科衛生士学校', ''),
    breadcrumb: slug === "/" ? [] : breadcrumb
  }
}