import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const breadcrumbItem = ({href, itemName}) => {
  const itemTag = href ? <a href={href}>{itemName}</a> : itemName
  return <li>{itemTag}</li>
}

const Breadcrumb = ({ category="", area="", prefecture="", title="" }) => {
  const siteMetadata = useSiteMetadata()
  const categoryName = siteMetadata.categorys[category] ? siteMetadata.categorys[category] : false
  return (
    <div className="p-breadcrumbs">
      <ol>
        <li><a href="/">{siteMetadata.title}</a></li>
        {categoryName && title !== categoryName ? breadcrumbItem({href: (title ? `/${category}` : ""), itemName: categoryName}) : false}
        {area ? breadcrumbItem({href: (title ? `/${category}/${area}` : ""), itemName: `${(siteMetadata.area[area] ? siteMetadata.area[area] : 'その他')}`}) : false}
        {prefecture && prefecture !== "hokkaido" ? breadcrumbItem({href: (title ? `/${category}/${area}/${prefecture}` : ""), itemName: `${(siteMetadata.prefectures[prefecture] ? siteMetadata.prefectures[prefecture] : 'その他')}`}) : false}
        {(title ? <li>{title}</li> : false)}
      </ol>
    </div>
  )
}

export default Breadcrumb