import { useStaticQuery, graphql } from "gatsby"

export const SchoolCount = () => {
  const { area } = useStaticQuery(
    graphql`
      query {
        area: allSchool(filter: { prefecture: { ne: "" } }) {
          group(field: area) {
            fieldValue
            totalCount
          }
        }
      }
    `
  )
  
  return area.group
}