import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function Seo({ description="", meta, title, slug="", lang='ja', category='', keywords="", breadcrumbData={} }) {
  const siteMetadata = useSiteMetadata()
  const pageTitle = title ? title : siteMetadata.title
  const metaDescription = description ? description : siteMetadata.description
  const metaKeywords = keywords ? keywords : ""
  const ogType = slug === "/" ? "website" : "article"
  const ogUrl = `${siteMetadata.siteUrl}${slug}`
  let ogImage = '/img/social.png'

  const breadcrumb = [
    {
      "@type": "ListItem",
      "position": 1,
      "name": `${siteMetadata.title}`,
      "item": `${siteMetadata.siteUrl}`
    }
  ]
  if (category && siteMetadata.categorys[category]) {
    breadcrumb.push(
      {
        "@type": "ListItem",
        "position": 2,
        "name": `${siteMetadata.categorys[category]}`,
        "item": `${siteMetadata.siteUrl}/${category}/`
      }
    )
  }

  const breadcrumbLength = breadcrumbData.breadcrumb.length
  if (category === "school") {
    const [, area, prefecture] = breadcrumbData.breadcrumb
    if (siteMetadata.area[area]) {
      breadcrumb.push(
        {
          "@type": "ListItem",
          "position": (breadcrumb.length + 1),
          "name": siteMetadata.area[area],
          "item": `${siteMetadata.siteUrl}/${category}/${area}/`
        }
      )
    }
    if (siteMetadata.prefectures[prefecture]) {
      breadcrumb.push(
        {
          "@type": "ListItem",
          "position": (breadcrumb.length + 1),
          "name": siteMetadata.prefectures[prefecture],
          "item": `${siteMetadata.siteUrl}/${category}/${area}/${prefecture}/`
        }
      )
    }
    if (breadcrumbLength > 3 || (area === "hokkaido" && breadcrumbLength > 2)) {
      breadcrumb.push(
        {
          "@type": "ListItem",
          "position": (breadcrumb.length + 1),
          "name": `${breadcrumbData.pageTitle ? breadcrumbData.pageTitle : pageTitle}`,
          "item": `${siteMetadata.siteUrl}${encodeURI(slug)}`
        }
      )
    }
  } else if (slug !== "/" && slug !== `/${category}/`) {
    breadcrumb.push(
      {
        "@type": "ListItem",
        "position": (breadcrumb.length + 1),
        "name": `${breadcrumbData.pageTitle ? breadcrumbData.pageTitle : pageTitle}`,
        "item": `${siteMetadata.siteUrl}${encodeURI(slug)}`
      }
    )
    const basename = breadcrumbData.breadcrumb.pop()
    ogImage = category === "top" ? `/img/${basename}/social.png` : `/img/${category}/${basename}/social.png`
  }

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumb
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      // titleTemplate={`%s${slug !== "/" ? ` | ${siteMetadata.title}` : ""}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
      .concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  keywords: ``,
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo