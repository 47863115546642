const queryCountList = (queryGroupData) => {
    const ret = {}
    for (const data of queryGroupData) {
        ret[data.fieldValue] = data.totalCount
    }
    return ret
}

const formatDate = (date, format="Y.M.D") => {
    const targetDate = new Date(Date.parse(date.replace(/-/g, '/')))
    let formatDate = format.replace("Y", targetDate.getFullYear())
    formatDate = formatDate.replace("M", targetDate.getMonth()+1)
    formatDate = formatDate.replace("D", targetDate.getDate())
    return formatDate
}

export { queryCountList, formatDate }
export default queryCountList