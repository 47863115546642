import React from "react"
import { Link } from "gatsby"
import Breadcrumb from "./breadcrumb"

const Footer = ({ category="", area="", prefecture="", title="", slug="" }) =>{
    return(
        <footer className="l-footer">
            {slug !== "/" && (
            <React.Fragment>
                <div className="p-button">
                    <Link to="/" className="c-button c-button-back">トップに戻る</Link>
                </div>
                <Breadcrumb category={category} area={area} prefecture={prefecture} title={title} />
            </React.Fragment>
            )}

            <div className="l-footer-inner">
                <div className="p-footer-site-name">
                    <Link to="/">
                    <span>めざせ!歯科衛生士</span>
                    <span>歯科衛生士になるための情報マガジン</span>
                    </Link>
                </div>

                <div className="p-footer-nav">
                    <nav>
                        <div className="c-global-nav-1st">
                            <ul>
                                <li>
                                    <Link to="/naruniha">歯科衛生士に<span>なるには</span></Link>
                                </li>
                                <li>
                                    <Link to="/shigoto">歯科衛生士の<span>仕事内容</span></Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <span>歯科衛生士の魅力</span>
                                    <ul>
                                    <li>
                                        <Link to="/salary">大卒より<em>高い初任給</em></Link>
                                    </li>
                                    <li>
                                        <Link to="/shikaku">一生使える<em>国家資格</em></Link>
                                    </li>
                                    <li>
                                        <Link to="/employment">就職率はなんと<em>9割以上</em></Link>
                                    </li>
                                    <li>
                                        <Link to="/hobby">趣味や遊びも<em>両立</em></Link>
                                    </li>
                                    <li>
                                        <Link to="/medical">患者さんを<em>笑顔にする医療職</em></Link>
                                    </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div className="c-global-nav-2nd">
                            <ul>
                                <li><Link to="/voice">学生VOICE</Link></li>
                                <li><Link to="/active">歯科衛生士の活躍</Link></li>
                                <li><Link to="/future">歯科衛生士の将来</Link></li>
                                <li><Link to="/qa">歯科衛生士Q&amp;A</Link></li>
                                <li><Link to="/school">全国の歯科衛生士学校</Link></li>
                            </ul>
                            <ul>
                                <li><Link to="/news">お知らせ</Link></li>
                                <li><Link to="/about">めざせ！歯科衛生士について</Link></li>
                                <li><a href="https://www.guppy.co.jp/contact/" target="_blank">コンタクト</a></li>
                                <li><Link to="/terms" >利用規約</Link></li>
                                <li><a href="https://www.guppy.co.jp/policy/" target="_blank">プライバシーポリシー</a></li>
                                <li><Link to="/sitemap">サイトマップ</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <div className="p-footer-copyright">
                    <Link to="/">株式会社グッピーズ</Link>
                    <span>Copyright &copy; GUPPY’s Inc. All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    )
}

export default Footer