import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            categorys {
              key
              name
              heading
            }
            area {
              key
              name
              order
            }
            prefectures {
              key
              name
              order
            }
            interviwCategorys
          }
        }
      }
    `
  )
  
  const ret = {...site.siteMetadata}
  const sortKeys = ["area", "prefectures"]
  for (let sKey of sortKeys) {
    ret[sKey].sort((a, b) => {
      if (a.order < b.order) return -1
      if (a.order > b.order) return 1
      return 0
    })
    ret[`${sKey}SortList`] = ret[sKey]
  }
  const dataKeys = ["categorys", "area", "prefectures"]
  for (let dKey of dataKeys) {
    let tempData = {}
    ret[dKey].forEach((value) => {
      tempData[value.key] = value.name
    })
    ret[dKey] = tempData
  }
  ret['categoryHeadings'] = {}
  for (const categoryData of site.siteMetadata.categorys) {
    if (categoryData.heading.length === 0) continue
    ret['categoryHeadings'][categoryData.key] = categoryData.heading
  }
  return ret
}